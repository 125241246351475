import { Controller } from '@hotwired/stimulus'
import CustomEvents from '../custom/customEvents'
const searchFormInputChangedEvent = new CustomEvents().searchFormInputChangedEvent()

export default class extends Controller {
  static targets = []

  connect () {

    const theme = {
      variables: {
        fontFamily: 'Poppins',
        borderRadius: '0',
        boxShadow: '0 0 0 0px',
      },  icons: {
        search: ''
      },
      cssText: '.Input{padding-left:0;font-size:12px;height:26px;}' +
        '  @media (min-width: 1920px) {\n' +
        '        .Input{font-size: 15px;height:36px;}\n' +
        '      }.SearchIcon{display:none}.Results{z-index:var(--z-index-mapbox-search)}'
    }

    const script = document.getElementById('search-js');
    // wait for the Mapbox Search JS script to load before using it
    script.onload = function () {
      // select the MapboxSearchBox instance
      const searchBox = document.querySelector('mapbox-search-box')
      searchBox.input.value = document.querySelector('mapbox-search-box').getAttribute('value')
      // set the options property
      searchBox.options = {
        language: 'fr',
        country: 'FR,MC,GP,MQ,GF,RE',
        types: ['street', 'place', 'postcode', 'address']
      }
      searchBox.input.setAttribute("autocomplete", "off")
      searchBox.input.dataset['1pIgnore'] = 'true';

      searchBox.theme = theme;
      searchBox.placeholder = 'Ville ou adresse';

      // add an event listener to handle the `retrieve` event
      searchBox.addEventListener('retrieve', (e) => {
        const feature = e.detail;
        const name = `${feature.features[0].properties.context.place.name}, ${feature.features[0].properties.context.region.name}`
        document.querySelector('#js-search_location_autocomplete').value = name
        const js_search_header_bar_location = document.querySelector('#js_search_header_bar_location')
        if(js_search_header_bar_location) {
          js_search_header_bar_location.innerHTML = name
        }
        const js_search_location_geocoded = document.querySelector('#js-search_location_geocoded')
        if(js_search_location_geocoded){
          js_search_location_geocoded.value = '';
        }
        // feature.features[0].geometry.coordinates
        document.dispatchEvent(searchFormInputChangedEvent)
      });
    }

    if (typeof google !== 'undefined') {
      this.initMap()
    }
  }

  initMap () {
    this.initializeAutocomplete('js-search_location_display_autocomplete')
    this.initializeAutocomplete(
      'js-search_location_display_autocomplete-mobile'
    )
    this.initializeAutocomplete('car_street')
  }

  initializeAutocomplete (id) {
    let autocomplete
    let options
    const element = document.getElementById(id)
    if (element) {
      if (id === 'car_street') {
        options = { types: ['geocode'] }
        autocomplete = new google.maps.places.Autocomplete(element, options)

        // Trigger map car_street
        google.maps.event.addListener(
          autocomplete,
          'place_changed',
          this.onPlaceChanged
        )
      }
      if (id === 'js-search_location_display_autocomplete') {
        options = {
          fields: ['name', 'place_id', 'address_components'],
          componentRestrictions: { country: ['fr', 'mc'] }
        }
        autocomplete = new google.maps.places.Autocomplete(element, options)

        // Trigger search_location_display_autocomplete
        google.maps.event.addListener(
          autocomplete,
          'place_changed',
          this.onCityChanged
        )
      }

      if (id === 'js-search_location_display_autocomplete-mobile') {
        options = {
          fields: ['name', 'place_id', 'address_component'],
          componentRestrictions: { country: 'fr' }
        }
        autocomplete = new google.maps.places.Autocomplete(element, options)

        // Trigger search_location_display_autocomplete mobile
        google.maps.event.addListener(
          autocomplete,
          'place_changed',
          this.onCityChanged
        )
      }

      google.maps.event.addDomListener(element, 'keydown', function (e) {
        if (e.keyCode === 13) {
          e.preventDefault()

          setTimeout(function () {
            document.dispatchEvent(searchFormInputChangedEvent)
          }, 700)
        }
      })
    }
  }

  onPlaceChanged () {
    const place = this.getPlace()
    const components = getAddressComponents(place, 'fulladdress')

    document.querySelector('#car_street').value = components.address
    document.querySelector('#car_city').value = components.city
  }

  /**
   * @deprecated
   */
  onCityChanged () {
    const place = this.getPlace()
    const components = getAddressComponents(place, 'country')

    if (components.address && components.city) {
      document.querySelector('#js-search_location_display_autocomplete').value =
        components.address
      document.querySelector('#js-search_location_autocomplete').value =
        components.city
    } else {
      document.querySelector('#js-search_location_autocomplete').value = ''
    }
    document.dispatchEvent(searchFormInputChangedEvent)
  }
}

function getAddressComponents (place, outputType) {
  let street_number = null
  let route = null
  let zip_code = null
  let city = null
  let country = null

  for (const i in place.address_components) {
    const component = place.address_components[i]
    for (const j in component.types) {
      const type = component.types[j]
      if (type === 'street_number') {
        street_number = component.long_name
      } else if (type === 'route') {
        route = component.long_name
      } else if (type === 'postal_code') {
        zip_code = component.long_name
      } else if (type === 'locality') {
        city = component.long_name
      } else if (type === 'country') {
        country = component.long_name
      }
    }
  }
  if (outputType === 'fulladdress') {
    return {
      address: street_number == null ? route : street_number + ' ' + route,
      street_number,
      route,
      zip_code,
      city,
      country
    }
  }
  if (outputType === 'country') {
    const address = city && zip_code ? zip_code + ' ' + city : city
    return {
      address,
      city: address
    }
  }
}
